import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import SaveDraft from '@/core/components/project/SaveDraft.vue';
import HeaderMenuSlot from '@/core/components/slots/HeaderMenuSlot.vue';
import HeaderMenuItem from '@/core/components/ui/HeaderMenuItem.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
export default defineComponent({
    components: {
        HeaderMenuSlot,
        HeaderMenuItem,
        SaveDraft
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(JOB_TEMPLATE_STORE, ['isOnEstimateForm']),
    },
    data() {
        return {
            menus: [
                {
                    route: 'jobs',
                    icon: 'details-menu-icon.svg',
                    menuName: 'Jobs',
                },
                {
                    route: 'templates',
                    icon: 'estimate-icon.svg',
                    menuName: 'Templates',
                },
                {
                    route: 'clients',
                    icon: 'schedule-icon.svg',
                    menuName: 'Clients',
                },
            ],
        };
    },
    methods: {
        ...mapActions(JOB_TEMPLATE_STORE, ['setIsOnEstimateForm', 'setEstimateForm']),
        routeTo(event) {
            this.$router.push({ name: event });
        },
        onCancel() {
            const { params } = this.$route;
            if (params?.id) {
                this.$router.replace({ name: 'templates' });
            }
            this.setEstimateForm({
                projectStages: [
                    {
                        name: '',
                        stageType: 'labour',
                        totalDuration: 0,
                        durationType: 1,
                        stageCost: 0,
                        projectStageTasks: [],
                        isNew: true,
                        isUsePhaseTotal: null,
                        requiredDeposit: false,
                        vat: 0,
                        subTotal: 0,
                        note: ''
                    }
                ]
            });
            this.setIsOnEstimateForm(false);
        }
    }
});
