import { defineComponent } from 'vue';
export default defineComponent({
    emits: ['route-to'],
    props: ['route', 'icon', 'menuName', 'isHide'],
    computed: {
        routeName() {
            return this.$route.name;
        },
    },
    methods: {
        routeTo() {
            this.$emit('route-to', this.route);
        }
    }
});
