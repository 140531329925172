<template>
    <div class="save-draft flex-row ai-c jc-fe hide-in-mobile">
      <!-- <div class="save-draft__wrapper flex-row ai-c cursor-pointer" @click="saveDraft">
        <inline-svg :src="require(`@/assets/icons/save-draft-icon.svg`)"></inline-svg>
        <p class="save-draft __draft default-text">Save draft</p>
      </div>
      <el-divider direction="vertical"/> -->
      <p class="save-draft __cancel default-text cursor-pointer" @click="cancel">Cancel</p>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['save-draft', 'cancel'],
  methods: {
    saveDraft() {
      this.$emit('save-draft');
    },
    cancel() {
      this.$emit('cancel');
    }
  },
});
</script>
<style lang="scss" scoped>
.save-draft {
  gap: .5rem;
  &__wrapper {
    gap: .5rem;
  }
  &__draft {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
  &__cancel {
    color: rgba(12, 15, 74, 0.50);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.15px;
  }
}
</style>
