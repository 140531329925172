<template>
    <div class="header-menu-nav-container">
      <ul class="header-menu-nav-container__ul">
        <slot></slot>
      </ul>
    </div>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.header-menu-nav-container {
  border-radius: 8px;
  background: rgba(42, 42, 42, 0.05);
  height: 42px;
  padding: .1rem;

  &__ul {
    margin: 0;
    padding: 0;
    display:flex;
    justify-content: space-around;
    align-items:center;
  }
}

@include  media-sm-max-width() {
  .header-menu-nav-container {
    padding: .1rem 0;
    background: #FFF;
    border-radius: 0;
    // width: calc(100% - 1.5rem);
    width: 100%;

    &__ul {
      overflow-x: scroll;
      width: 100%;
      justify-content: space-between;
      padding: 0 1rem;
      box-sizing: border-box;
    }
  }
}
</style>
